import { AxiosResponse } from 'axios';

export interface VictoryResponse<T> {
  data: T;
  message: string;
  errors: any;
}

export const unpackHttpResponse = async <T>(
  httpResponse: AxiosResponse<VictoryResponse<T>>
): Promise<VictoryResponse<T>> => {
  const { data: responseData } = httpResponse;
  const { data, message, errors } = responseData;

  if (data === undefined || message === undefined || errors === undefined) {
    throw new Error('Out of shape');
  }

  return {
    data,
    message,
    errors,
  };
};
