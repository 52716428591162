import localforage from 'localforage';

const TOKEN_STORAGE_KEY = 'victory_sidebar_preferences';

export const storePreference = (preference: string): Promise<string> =>
  localforage.setItem(TOKEN_STORAGE_KEY, preference);

export const clearPreference = (): Promise<void> =>
  localforage.removeItem(TOKEN_STORAGE_KEY);

export const getPreferenceFromStorage = (): Promise<string | null> =>
  localforage.getItem(TOKEN_STORAGE_KEY);
