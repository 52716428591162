import { useApiUrl } from 'src/global/config/useApiUrl';
import { VictoryResponse } from './httpResponse';
import { AxiosResponseWithOk } from './useAxiosFetch';
import { useVictoryFetch, UseVictoryFetchApi } from './useVictoryFetch';

type FetchMethod = 'post' | 'get';

export interface UseEndpointFetch<Req, Res = unknown>
  extends Omit<UseVictoryFetchApi<Res>, 'run'> {
  run: (data: Req) => Promise<AxiosResponseWithOk<VictoryResponse<Res>>>;
}

export interface EndpointFetchOptions {
  withAuth?: boolean;
  method?: FetchMethod;
  formData?: boolean;
}
export const useEndpointFetch = <Req extends object, Res = unknown>(
  slug: string,
  options?: EndpointFetchOptions
): UseEndpointFetch<Req, Res> => {
  if (!slug.startsWith('/'))
    throw new Error('useEndpointFetch: slug must begin with a backslash');

  const apiUrl = useApiUrl();
  const fetch = useVictoryFetch<Res>({
    defer: true,
    withAuth: options?.withAuth ?? true,
  });

  const endpointRun = (data: Req) => {
    return fetch.run({
      method: options?.method ?? 'post',
      url: `${apiUrl}${slug}`,
      data: data,
    });
  };

  return {
    ...fetch,
    run: endpointRun,
  };
};
