import { createContext, useMemo, ReactNode, useEffect, useState } from 'react';
import {
  getPreferenceFromStorage,
  storePreference,
} from 'src/global/preferences/sidebar-pref-storage';

export interface PreferenceContextInterface {
  isSidebarOpen: boolean;
  setSidebarOpen: (v: boolean) => void;
}

export interface PreferenceProviderProps {
  value?: PreferenceContextInterface;
  children: ReactNode;
}

export const PreferenceContext = createContext<
  PreferenceContextInterface | undefined
>(undefined);

export const PreferenceProvider = (props: PreferenceProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    getPreferenceFromStorage().then((value) =>
      value === '1' ? setIsOpen(true) : setIsOpen(false)
    );
  }, []);

  const setSidebarOpen = (value: boolean) => {
    setIsOpen(value);
    storePreference(value === true ? '1' : '0');
  };

  const value: PreferenceContextInterface = useMemo(
    () => ({
      isSidebarOpen: isOpen,
      setSidebarOpen: setSidebarOpen,
    }),
    [isOpen]
  );

  return <PreferenceContext.Provider value={value} {...props} />;
};
