import {
  Box,
  Center,
  ChakraProvider,
  CircularProgress,
} from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { Route, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AuthProvider } from './global/auth/AuthContext';
import { useAuth } from './global/auth/useAuth';
import { ApiProvider } from './global/network/ApiContext';
import { VictoryApolloProvider } from './global/network/ApolloContext';
import { PageNotFoundProvider } from './global/routing/PageNotFoundContext';
import { VictoryRoutes } from './global/routing/VictoryRoutes';
import { theme } from './global/theme/theme';
import { PaginationProvider } from './global/pagination/PaginationContext';
import { PreferenceProvider } from 'src/global/preferences/PreferenceContext';

const PublicShell = React.lazy(
  () => import('./global/public-shell/PublicShell')
);
const AppShell = React.lazy(() => import('./global/app-shell/AppShell'));

const App = () => {
  return <RouterProvider router={router} />;
};

function Root() {
  return (
    <ChakraProvider theme={theme}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <PageNotFoundProvider>
          <ApiProvider>
            <AuthProvider>
              <VictoryApolloProvider>
                <PaginationProvider>
                  <PreferenceProvider>
                    <ShellGateway />
                  </PreferenceProvider>
                </PaginationProvider>
              </VictoryApolloProvider>
            </AuthProvider>
          </ApiProvider>
        </PageNotFoundProvider>
      </QueryParamProvider>
    </ChakraProvider>
  );
}

const router = createBrowserRouter([{ path: '*', Component: Root }]);

const ShellGateway = () => {
  return (
    <Suspense fallback={<GatewayFallback />}>
      <Box>
        <VictoryRoutes>
          <Route path='*' element={<Gateway />} />
        </VictoryRoutes>
      </Box>
    </Suspense>
  );
};

const Gateway = () => {
  const { token } = useAuth();

  if (!token) return <PublicShell />;

  return (
    <VictoryApolloProvider token={token}>
      <AppShell />
    </VictoryApolloProvider>
  );
};

const GatewayFallback = () => {
  return (
    <Center h={'100vh'}>
      <CircularProgress isIndeterminate color='green' />
    </Center>
  );
};

export default App;
