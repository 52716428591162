import jwtDecode from 'jwt-decode';

export interface VictoryTokenData {
  type: 'ApiUserToken' | 'UserToken';
  tenantId: string;
  userId: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
}

export interface ParsedToken {
  exp: number;
  iat: number;
  data: VictoryTokenData;
}

export const parseToken = (token: string): ParsedToken => {
  try {
    return jwtDecode<ParsedToken>(token);
  } catch (e) {
    throw new Error('Token cannot be parsed');
  }
};
