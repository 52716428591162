import { useContext } from 'react';
import { ApiContext } from 'src/global/network/ApiContext';

const useApiUrl = (): string => {
  const apiContext = useContext(ApiContext);
  if (!apiContext)
    throw new Error('useApiUrl must be initiated within an ApiProvider');

  return apiContext.apiUrl;
};

export { useApiUrl };
