import React, { createContext } from 'react';

export interface ApiContextInterface {
  apiUrl: string;
}

export interface ApiProviderProps {
  value?: ApiContextInterface;
  children: React.ReactNode;
}

export const ApiContext = createContext<ApiContextInterface | undefined>(
  undefined
);

export const ApiProvider = (props: ApiProviderProps) => {
  const value: ApiContextInterface = {
    apiUrl: process.env.REACT_APP_API_URL ?? 'http://localhost:9009/api',
  };
  return <ApiContext.Provider value={value} {...props} />;
};
