import { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { FourOhFourScreen } from 'src/screens/public/four-oh-four/FourOhFourScreen';

interface VictoryRoutesProps {
  children: ReactNode;
}

export const VictoryRoutes = (props: VictoryRoutesProps) => {
  const { children } = props;

  return (
    <Routes>
      {children}
      <Route path='*' element={<FourOhFourScreen />} />
    </Routes>
  );
};
