import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { createContext, useState } from 'react';
import { useApiUrl } from 'src/global/config/useApiUrl';

interface ApolloContextInterface {
  client: ApolloClient<any>;
}

const ApolloContext = createContext<ApolloContextInterface | undefined>(
  undefined
);

const createApolloClient = (apiUrl: string, token?: string) => {
  const graphQLEndpoint = `${apiUrl}/gql`;
  const cache = new InMemoryCache();
  const httpLink = new HttpLink({
    uri: graphQLEndpoint,
    headers: token
      ? {
          authorization: `Bearer ${token}`,
        }
      : {},
  });

  const operationLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ uri = graphQLEndpoint }: { uri: string }) => ({
      uri: `${uri}?op=${operation.operationName}`,
    }));

    return forward(operation);
  });

  return new ApolloClient({
    cache: cache,
    link: from([operationLink, httpLink]),
    queryDeduplication: true,
  });
};

interface ApolloProviderProps {
  children: React.ReactNode;
  token?: string;
}

export const VictoryApolloProvider = ({
  children,
  token,
}: ApolloProviderProps) => {
  const apiUrl = useApiUrl();
  const [client] = useState(createApolloClient(apiUrl, token));
  return (
    <ApolloContext.Provider value={{ client }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ApolloContext.Provider>
  );
};
