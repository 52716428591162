import localforage from 'localforage';

const TOKEN_STORAGE_KEY = 'victory_user_auth_token';

export const storeToken = (token: string): Promise<string> =>
  localforage.setItem(TOKEN_STORAGE_KEY, token);

export const clearToken = (): Promise<void> =>
  localforage.removeItem(TOKEN_STORAGE_KEY);

export const getTokenFromStorage = (): Promise<string | null> =>
  localforage.getItem(TOKEN_STORAGE_KEY);
