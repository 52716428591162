import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { VictoryResponse, unpackHttpResponse } from './httpResponse';
import {
  AxiosResponseWithOk,
  UseAxiosFetchApi,
  useAxiosFetch,
} from './useAxiosFetch';
import { useAuth } from 'src/global/auth/useAuth';

export interface UseVictoryFetchOptions {
  axiosOptions?: AxiosRequestConfig;
  defer?: boolean;
  withAuth?: boolean;
}

export type UseVictoryFetchApi<T> = UseAxiosFetchApi<VictoryResponse<T>> & {
  status: 'initial' | 'pending' | 'fulfilled' | 'rejected';
};

/*
 * This function needs to exist so we can be run requests
 * inside AuthContext.tsx without running into conditional
 * hook render issues that would arise otherwise.
 */
const useVictoryFetchWithoutAuth = <ResponseType>({
  axiosOptions = {},
  defer = false,
}: UseVictoryFetchOptions): UseVictoryFetchApi<ResponseType> => {
  type ResponseBundle = {
    error: Error | undefined;
    isLoading: boolean;
    response: AxiosResponseWithOk<VictoryResponse<ResponseType>> | undefined;
  };

  const [responseBundle, setResponseBundle] = useState<ResponseBundle>({
    error: undefined,
    isLoading: false,
    response: undefined,
  });

  const activateResponse = (
    r: AxiosResponseWithOk<VictoryResponse<ResponseType>>
  ) => {
    setResponseBundle({
      response: r,
      isLoading: false,
      error: undefined,
    });
  };

  const activateError = (e: Error) => {
    setResponseBundle({
      response: undefined,
      isLoading: false,
      error: e,
    });
  };

  const {
    run,
    reload,
    response: axiosResponse,
    error: axiosRequestError,
    ...restAxiosFetch
  } = useAxiosFetch<VictoryResponse<ResponseType>>({
    axiosOptions,
    defer,
    allowErrorResponse: true,
  });

  useEffect(() => {
    const handleDataResolve = async () => {
      if (axiosResponse === undefined) return;
      try {
        await unpackHttpResponse<ResponseType>(axiosResponse);
        activateResponse(axiosResponse);
      } catch (e) {
        activateError(new Error('Something went wrong'));
      }
    };
    handleDataResolve();
  }, [axiosResponse]);

  useEffect(() => {
    // Catch timeouts and other really big broken situations
    if (axiosRequestError === undefined) return;
    activateError(new Error('Something went wrong'));
  }, [axiosRequestError]);

  const isResponseNotOk =
    restAxiosFetch.status !== 'pending' &&
    responseBundle &&
    responseBundle.response &&
    !responseBundle.response.ok;

  const status = isResponseNotOk ? 'rejected' : restAxiosFetch.status;

  return {
    run,
    reload,
    ...responseBundle,
    ...restAxiosFetch,
    status,
  };
};

export const useVictoryFetch = <ResponseType>({
  axiosOptions,
  defer = false,
  withAuth = true,
}: UseVictoryFetchOptions): UseVictoryFetchApi<ResponseType> => {
  const { token } = useAuth();
  if (!token && withAuth) {
    throw new Error(
      `useVictoryFetch default behaviour requires an authorization token to be returned by useAuth. 
      If you are making a request that doesn't require auth then pass withAuth: false into the useVictoryFetch options.`
    );
  }

  const authorizationAxiosOptions: AxiosRequestConfig = {
    ...axiosOptions,
    headers: {
      ...(axiosOptions ? axiosOptions.headers : {}),
      Authorization: token !== undefined ? `Bearer ${token}` : undefined,
    },
  };

  return useVictoryFetchWithoutAuth<ResponseType>({
    axiosOptions: authorizationAxiosOptions,
    defer,
  });
};
