import { ReactNode, createContext, useMemo } from 'react';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

export interface PaginationContextInterface {
  page: number;
  setPage: (page: number) => void;
  pagination: { limit: number; offset: number };
}

export const PaginationContext = createContext<
  PaginationContextInterface | undefined
>(undefined);

const NumberDefaultParam = withDefault(NumberParam, 1);
export const RESULTS_PER_PAGE = 50;

export const PaginationProvider = ({ children }: { children: ReactNode }) => {
  const [page, setPage] = useQueryParam('page', NumberDefaultParam);

  const pagination = useMemo(() => {
    return {
      limit: RESULTS_PER_PAGE,
      offset: RESULTS_PER_PAGE * (page - 1),
    };
  }, [page]);

  const contextValue = {
    page,
    setPage,
    pagination,
  };

  return (
    <PaginationContext.Provider value={contextValue}>
      {children}
    </PaginationContext.Provider>
  );
};
