import { DateTime } from 'luxon';
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useEndpointFetch } from 'src/global/network/useEndpointFetch';
import { parseToken } from './token-parser';
import { clearToken, getTokenFromStorage, storeToken } from './token-storage';

interface User {
  userId: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface AuthContextType {
  user?: User;
  loading: boolean;
  error?: Error;
  token?: string;
  login: (email: string, password: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

interface TokenResponse {
  token: string;
}

export const AuthProvider = function ({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | undefined>();
  const [token, setToken] = useState<string | undefined>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const authUser = useEndpointFetch<
    { email: string; password: string },
    TokenResponse
  >('/auth/user', { withAuth: false });

  const login = useCallback(
    (email: string, password: string) => {
      setLoading(true);
      setError(undefined);
      authUser
        .run({
          email,
          password,
        })
        .then((res) => {
          if (res.ok) {
            setToken(res.data.data.token);
            return;
          } else {
            throw new Error(res.data.message);
          }
        })
        .catch((err: Error) => setError(err))
        .finally(() => setLoading(false));
    },
    [authUser]
  );

  function logout() {
    setError(undefined);
    setUser(undefined);
    setToken(undefined);
    clearToken();
    navigate('/');
  }

  useEffect(() => {
    getTokenFromStorage().then((t) => setToken(t ?? undefined));
  }, []);

  useEffect(() => {
    if (!token) return;
    const parsedToken = parseToken(token);
    if (parsedToken.exp <= DateTime.now().toUnixInteger()) logout();
    storeToken(token);
    setUser({
      userId: parsedToken.data.userId,
      name: parsedToken.data.name,
      email: parsedToken.data.email,
      firstName: parsedToken.data.firstName ?? '',
      lastName: parsedToken.data.lastName ?? '',
    });
  }, [token]);

  const contextValue = useMemo(
    () => ({
      user,
      loading,
      error,
      token,
      login,
      logout,
    }),
    [user, loading, error, token, login, logout]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
